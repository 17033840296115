var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-56", attrs: { id: "help" } },
    [
      _c(
        "Toolbar",
        [
          _c(
            "v-btn",
            {
              attrs: {
                slot: "btn",
                icon: "",
                color: "success",
                to: "/service"
              },
              slot: "btn"
            },
            [
              _c("Icon", {
                staticClass: "icon--text",
                attrs: {
                  data: require("@icon/bottomNav/service.svg"),
                  width: "24px",
                  height: "24px"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        grow: "",
                        "background-color": "bg",
                        color: "primary"
                      },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    _vm._l(_vm.document, function(item) {
                      return _c(
                        "v-tab",
                        {
                          key: item._id,
                          class: [{ "px-0": _vm.lang === "zh-CN" }]
                        },
                        [
                          _c("span", { staticClass: "subtitle-2" }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ]
                      )
                    }),
                    1
                  ),
                  _c("v-divider", { staticClass: "divider" })
                ],
                1
              ),
              _vm.document.length > 0
                ? _c(
                    "v-col",
                    { staticClass: "pt-0 px-4", attrs: { cols: "12" } },
                    _vm._l(_vm.activeContent.docs, function(doc, index) {
                      return _c(
                        "div",
                        { key: doc + index, staticClass: "doc mt-4" },
                        [
                          _c(
                            "div",
                            { staticClass: "doc__title primary--text d-flex" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "white--text question primary mb-0 text-center"
                                },
                                [_c("span", [_vm._v("Q")])]
                              ),
                              _vm._v(" " + _vm._s(doc.name) + " ")
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "doc__content caption title--text mt-3 mb-6"
                            },
                            _vm._l(doc.content.split("\r\n"), function(
                              text,
                              itemIndex
                            ) {
                              return _c("div", { key: text + itemIndex }, [
                                _vm._v(" " + _vm._s(text) + " ")
                              ])
                            }),
                            0
                          ),
                          _c("v-divider", { staticClass: "divider" })
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }